.banner-container {
  height: 250px;
  width: 100%;
}

.main-banner{
	background-image: url('./skola.png');
	background-repeat: no-repeat;
	background-size: cover;
	height: 250px !important;
}

.main-news-image{ 
  background-image: url('../../assets/noimage.png');
	background-repeat: no-repeat;
	background-size: cover;
	height: 500px !important;
  padding: 0px !important;
  background-color: black;
}

.main-text{
    color: white;
    font-family: Montserrat, Helvetica, Arial, Lucida, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 60px;
    text-shadow: 2px 2px 10px #222222;
    line-height: 76px;
    border-bottom: 6px solid teal;
}

.main-sub-text{
	/*font-family: Open Sans, Arial, sans-serif;*/
    font-family: Raleway, Helvetica, Arial, Lucida, sans-serif;
    
    font-weight: bold;
    font-style: normal;
    font-size: 21px;
    color: #ffff;
    line-height: 25px;
}