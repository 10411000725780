/**/
/*#003845*/

.scc-cards-section {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 60px;
    
    background-color: rgba(233, 221, 221, 0.5);
    /*background-color: rgba(243, 229, 251, 0.45);*/
}

.scc-cards-section2 {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 60px;
    
    background-color: #b2dfdb;
    /*background-color: rgba(243, 229, 251, 0.45);*/
}

.center .center-align {
    text-align: center;
}

.scc-cards_item {
    display: flex;
    /*padding: 1rem;*/
}
.scc-card_bgimage{
    position: absolute;
    background-color: #0000006e;
    top: 0;
    left: 0;
    width: 100%;
    height: 98%;
}
.scc-card {
    background-color: #fff;
    border-radius: .25rem;
    box-shadow: 0 20px 40px -14px rgb(0 0 0 / 50%);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    cursor: pointer;
}

.scc-card_content {
    padding: 5px;
    height: 400px !important;
    max-height: 350px !important;
    background-color: #fff;
    border-bottom: 4px solid rgba(47, 43, 43, 0.5);
}
.cardHeadline{
    font-weight: bold;
    text-align: center;
    font-size: 20px;
    padding-bottom: 10px;
    color: rgba(47, 43, 43, 1);
    /*border-bottom: 1px solid rgba(47, 43, 43, 0.5);*/
    /*rgba(47, 43, 43, 0.5);*/
    /*rgb(150, 102, 0);*/
    
}

.button-show-more:hover {
    background-color: #00ff1e0e;
}

.button-show-more {
    cursor: pointer;
    padding: 11px;
    border: 1px solid #000 !important; 
    border-radius: 444px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.scc-card_image{
    background-image: url('../../assets/noimage.png');
    background-size: cover;
    height: 250px !important;
    background-repeat: no-repeat;
    background-position: center;
}

.coverNewsContentOverflow{
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  text-align: justify;
}

.cardNewsContent{
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  text-align: justify;

}

.cardNewsContent:before {
  content:'';
  width:100%;
  height:100%;    
  position:absolute;
  left:0;
  top:0;
  /*border: 1px solid red;*/
  background:linear-gradient(transparent 250px, white);
}

.cardNewsRightCol{
   max-height: 350px !important;
  overflow: hidden !important;

}

.newsContent:{
  text-align: justify;
  text-justify: inter-word;
}