.news-section {
    width: 100%;
    padding-top: 35px;
    padding-bottom: 60px;  

}
.titleNews{
    font-size: 40px;
    margin-top: 26px;
    margin-bottom: 26px;
    font-weight: bold;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(68, 66, 58, 0.5) ;
}

.news-section > div > div {
    cursor: pointer;
}

.titleNews:hover{
   color: rgb(17, 92, 102);
   text-decoration: underline;
}
a:hover {
    text-decoration: underline;
}
.news-card {
    border: 2px solid rgba(0, 150, 135, 0);
    transition: 0.6s;
}

.news-card:hover {
  /* border: 2px solid rgb(0, 150, 136);*/
  border: 2px solid rgba(0, 150, 135, 0.466);
}

.newsContent:{
  text-align: justify;
  text-justify: inter-word;
}

.coverNewsContent{
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  text-align: justify;
}

.coverNewsContent:before {
  content:'';
  width:100%;
  height:100%;    
  position:absolute;
  left:0;
  top:0;
  /*border: 1px solid red;*/
  background:linear-gradient(transparent 400px, white);
}

.coverNewsRightCol{
   max-height: 470px !important;
  overflow: hidden !important;

}