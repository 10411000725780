.scc-cards-section {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 60px;
    background-color: rgba(207, 207, 207, 0.5);
}
.center .center-align {
    text-align: center;
}

.scc-cards_item {
    display: flex;
    padding: 1rem;

}
.scc-card_bgimage{
    position: absolute;
    background-color: #0000006e;
    top: 0;
    left: 0;
    width: 100%;
    height: 98%;
}
.scc-card {
    background-color: #fff;
    border-radius: .25rem;
    box-shadow: 0 20px 40px -14px rgb(0 0 0 / 50%);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    cursor: pointer;
    
}

.scc-card_content-dec {
    padding: 1rem;
    height: 220px;
    min-height: 220px !important; 
    background-color: #fff;
    border-bottom: 4px solid rgba(47, 43, 43, 0.5);
}
.cardHeadline{
    font-weight: bold;
    text-align: center;
    font-size: 22px !important;
    padding-bottom: 10px;
    color: rgba(47, 43, 43, 1);
    /*rgba(47, 43, 43, 0.5);*/
    /*rgb(150, 102, 0);*/
    
}

.cardDesHeadline{
    font-weight: bold;
    text-align: center;
    font-size: larger !important;
    padding-bottom: 10px;
    color: rgba(47, 43, 43, 1);
    /*rgba(47, 43, 43, 0.5);*/
    /*rgb(150, 102, 0);*/
    
}

.button-show-more:hover {
    background-color: #00ff1e0e;
}

.button-show-more-d {
    cursor: pointer;
    padding: 11px;
    border: 1px solid #3b3b3b !important; 
    border-radius: 444px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.scc-card_image{
    background-image: url('../../assets/noimage.png');
}


