.pages-padd{
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
    margin: 0px !important;
    margin-top: 20px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;

}
.newsTitle-txt{
    text-align: center;
    /*padding-bottom: 10rem;*/
}
.centered-elem{
    display: flex;
    align-items: center;
    justify-content: center;
}
.centered-elem img{
    height: 400px;
}
.txt-content{
    /*padding-top: 2.674rem;*/
    padding-top: 0px;
}
.doc-block{
    display: flex;
    padding: 1.424rem 0.75rem 0 0.75rem;
}
.doc-line-block{
    flex : 5;
    display: flex;
     align-items: center;
}
.line{
    width: 100%;
    background-color: rgba(47, 43, 43, 0.5);
    height: 1px;
    margin-top: 20px;
    margin-left: 5px;
}
.doc{
    display: flex;
    align-items: center;
    justify-content: space-between;
     padding: 1.424rem 0rem 1.24rem 0rem;
     margin-left: 0.75rem;
    margin-right: 0.75rem;
}
.doc-font{
    color:rgb(0, 150, 136);
     font-weight: bold;
}
.doc-button{
    padding: 10px 25px 10px 25px;
    border-radius: 4px;
    background-color: rgb(0, 150, 136);
    color: white;
    border: 0px;
}